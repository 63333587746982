import React from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "../layouts";
import Article from "../components/Article/";
import Headline from "../components/Article/Headline";
import Seo from "../components/Seo";
import { graphql } from "gatsby";

const CursosPage = props => (
  <>
    <ThemeContext.Consumer>
      {theme => (
        <Article theme={theme}>
          <header>
            <Headline title="Cursos" theme={theme} />
          </header>
          <section>
            Próximamente encontrarás cursos en varios formatos, enfocados en desarrollo web en
            especial cubriendo Javascript como lenguaje y diferentes frameworks modernos.
          </section>
        </Article>
      )}
    </ThemeContext.Consumer>
    <Seo facebook={props.data.site.siteMetadata.facebook} />
  </>
);

CursosPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default CursosPage;

//eslint-disable-next-line no-undef
export const query = graphql`
  query CursosQuery {
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
  }
`;
